<template>
  <v-card width="100%" class="mb-1">
    <v-container>
      <v-row>
        <v-col cols="12" class="pb-0 pt-1">
          <p class="ma-0 overline grey--text ml-1">
            Ordenes {{ filterBy == "Mes" ? "del Mes" : "de Hoy" }}
          </p>
          <v-select
            v-model="filterBy"
            :items="filterByData"
            @change="eventChanged"
            item-text="title"
            item-value="value"
            return-object
            outlined
            :loading="loader"
            dense
            hide-details
          ></v-select>
          <v-select
            v-show="filterBy === 'Mes'"
            class="mt-1"
            ref="filterMonth"
            v-model="currentMonth"
            :items="recordsFilter"
            @change="changeFilterByMonth"
            item-text="title"
            item-value="value"
            return-object
            outlined
            :loading="loader"
            dense
            hide-details
          ></v-select>
        </v-col>

        <v-col cols="12" v-if="false">
          <v-text-field
            ref="barcode"
            v-model="barcode"
            disabled
            label="Buscar por código"
            type="number"
            outlined
            dense
            hide-details
            append-icon="camera_alt"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      filterBy: "Hoy",
      filterByData: ["Hoy", "Mes"],
      currentMonth: {},
      barcode: null
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getActualRecordFilter() {
      const nameMonth = this.capitalizeFirstLetter(
        moment()
          .format("MMMM")
          .toString()
      );
      const fullYear = moment().format("YYYY");
      const currentMonth = parseInt(moment().month()) + 1;
      const currentSale = `${currentMonth}${moment().year()}`;
      const data = {
        title: nameMonth + " " + fullYear,
        value: parseInt(currentSale)
      };
      return data;
    },
    eventChanged(el) {
      if (el === "Mes") this.$refs.filterMonth.isMenuActive = true;
      if (el === "Hoy") {
        this.currentMonth = {};
        this.$refs.filterMonth.isMenuActive = false;
        this.changeFilterByDay();
      }
    }
  },
  async mounted() {
    this.currentMonth = await this.getActualRecordFilter();
  },
  props: {
    recordsFilter: {
      type: Array,
      default: () => {}
    },
    loader: {
      type: Boolean,
      default: true
    },
    changeFilterByMonth: {
      type: Function
    },
    changeFilterByDay: {
      type: Function
    }
  }
};
</script>

<style></style>
