<template>
  <v-container :class="{ 'pr-0 pl-0 pt-0': !$vuetify.breakpoint.mdAndUp }">
    <v-row>
      <v-col cols="12" class="py-1">
        <h3 class="text-h5 ml-1 font-weight-medium">
          Ordenes
        </h3>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="5"
        lg="3"
        xl="3"
        order-lg="2"
        order-sm="1"
        order-md="2"
        class="pb-0"
      >
        <app-orders-filter
          :recordsFilter="recordsFilter"
          :loader="loadingRecordFilter"
          :changeFilterByMonth="changeFilterByMonth"
          :changeFilterByDay="changeFilterByDay"
        ></app-orders-filter>
        <app-total-sales
          :totalSold="totalSold"
          :loader="loaderTotalSold"
        ></app-total-sales>
        <app-total-benefit
          v-if="isAdminUser"
          :totalSold="totalSold"
          :totalBenefit="totalBenefit"
          :loader="loaderTotalSold"
        ></app-total-benefit>
        <app-total-orders
          :orders="totalOrders"
          :loader="loaderTotalOrders"
        ></app-total-orders>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="7"
        lg="9"
        xl="9"
        order-lg="1"
        order-sm="2"
        order-md="1"
        class="pt-0 pb-0"
        :class="{ 'pt-2': !$vuetify.breakpoint.mdAndUp }"
      >
        <v-card width="100%">
          <v-container>
            <v-row>
              <v-col cols="12" class="py-0">
                <p class="ma-0 text-h5">Lista de ordenes</p>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  label="Buscar orden"
                  v-model="searchOrder"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="sales"
                  :loading="loadingTable"
                  :search="searchOrder"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  @click:row="goToDetail"
                  @update:page="$vuetify.goTo(0)"
                  loading-text="Estamos preparando tus datos..."
                  :items-per-page="10"
                >
                  <template v-slot:item.created="{ item }">
                    <span>{{ item.created | transformDate }}</span>
                  </template>
                  <template v-slot:item.total_products="{ item }">
                    <v-chip>{{ item.total_products }}</v-chip>
                  </template>
                  <template v-slot:item.total_sold="{ item }">
                    <span v-if="item.payment_method === 0" class="mr-2"
                      ><v-icon>payments</v-icon></span
                    >
                    <span v-else class="mr-2"
                      ><v-icon>credit_card</v-icon></span
                    >
                    <span>$ {{ item.total_sold }}</span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TotalSales from "@/components/orders/TotalSales";
import TotalBenefit from "@/components/orders/TotalBenefit";
import TotalOrders from "@/components/orders/TotalOrders";
import Filter from "@/components/orders/Filter";
import { database } from "@/config/firebase";
import { errorMessage } from "@/services/MessageService";
import { mapGetters, mapState } from "vuex";
import moment from "moment";

export default {
  components: {
    AppTotalSales: TotalSales,
    AppTotalOrders: TotalOrders,
    AppOrdersFilter: Filter,
    AppTotalBenefit: TotalBenefit
  },
  data() {
    return {
      sortBy: "created",
      sortDesc: true,
      searchOrder: null,
      sales: [],
      loadingTable: true,
      databaseRef: null,
      loadingRecordFilter: true,
      recordsFilter: [],
      headers: [
        { text: "Fecha", value: "created" },
        { text: "Ticket", value: "id" },
        { text: "Productos", value: "total_products" },
        { text: "Total de venta", value: "total_sold" }
      ],
      totalOrders: 0,
      loaderTotalOrders: true,
      totalSold: 0,
      totalBenefit: 0,
      loaderTotalSold: true
    };
  },
  beforeMount() {
    this.getRecordFilter();
    this.getOrdersDataByDay();
  },
  methods: {
    async getOrdersDataByDay() {
      this.loadingTable = true;
      try {
        this.databaseRef = await database(
          `branch_offices/${this.currentBranchID}/analytics/sales/today`
        );
        this.databaseRef.on("value", async snapshot => {
          if (snapshot.exists()) {
            this.sales = [];
            this.totalOrders = snapshot.val().total_orders;
            this.totalSold = snapshot.val().total_sold;
            this.totalBenefit = snapshot.val().total_sold_price;
            // :: Orders List
            const orders = snapshot.val().sales_list;
            if (orders) {
              const sales = Object.keys(orders).map(key => orders[key]);
              this.sales = sales;
            }
            // :: Loaders false
            this.loadingTable = false;
            this.loaderTotalOrders = false;
            this.loaderTotalSold = false;
          } else {
            this.sales = [];
            this.loadingTable = false;
            this.loaderTotalOrders = false;
            this.loaderTotalSold = false;
          }
        });
      } catch (error) {
        this.loadingTable = false;
        this.loaderTotalOrders = false;
        this.loaderTotalSold = false;
        errorMessage({
          message:
            "Upss algo salio mal al obtener la información de las ordenes. Code:" +
            error
        });
      }
    },
    async getOrdersData(currentMonthSale) {
      this.loadingTable = true;
      try {
        this.databaseRef = await database(
          `branch_offices/${this.currentBranchID}/analytics/sales/${currentMonthSale}`
        );
        this.databaseRef.on("value", async snapshot => {
          if (snapshot.exists()) {
            this.sales = [];
            this.totalOrders = snapshot.val().total_orders;
            this.totalSold = snapshot.val().total_sold;
            this.totalBenefit = snapshot.val().total_sold_price;
            // :: Orders List
            const orders = snapshot.val().sales_list;
            if (orders) {
              const sales = Object.keys(orders).map(key => orders[key]);
              this.sales = sales;
            }
            // :: Loaders false
            this.loadingTable = false;
            this.loaderTotalOrders = false;
            this.loaderTotalSold = false;
          } else {
            this.sales = [];
            this.loadingTable = false;
            this.loaderTotalOrders = false;
            this.loaderTotalSold = false;
          }
        });
      } catch (error) {
        this.loadingTable = false;
        this.loaderTotalOrders = false;
        this.loaderTotalSold = false;
        errorMessage({
          message:
            "Upss algo salio mal al obtener la información de las ordenes. Code:" +
            error
        });
      }
    },
    async getRecordFilter() {
      this.loadingRecordFilter = true;
      try {
        await database("filters/filter_by_month").once(
          "value",
          async snapshot => {
            if (snapshot.exists()) {
              const records = snapshot.val();
              this.recordsFilter = records;
              this.loadingRecordFilter = false;
            } else {
              this.loadingRecordFilter = false;
            }
          }
        );
      } catch (error) {
        errorMessage({
          message:
            "Upss algo salio mal al obtener la información de los filtros. Code:" +
            error
        });
      }
    },
    goToDetail(order) {
      const orderID = order.id;
      this.$router.push({ name: "orderDetail", params: { orderID } });
    },
    async changeFilterByMonth(currentMonth) {
      await this.databaseRef.off("value");
      this.getOrdersData(currentMonth.value);
    },
    async changeFilterByDay() {
      await this.databaseRef.off("value");
      this.getOrdersDataByDay();
    },
    async removeListenerDatabase() {
      await this.databaseRef.off("value");
      this.databaseRef = null;
      this.sales = [];
      this.recordsFilter = [];
    }
  },
  filters: {
    transformDate(date) {
      return moment.unix(date).format("HH:mm [el] DD [de] MMM YYYY");
    }
  },
  beforeDestroy() {
    this.loadingTable = false;
    this.removeListenerDatabase();
  },
  computed: {
    ...mapGetters("user", ["isAdminUser"]),
    ...mapState("business", ["currentBranchID"]),
    paddingBrakpointOrders() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return "pa-2";
      } else {
        return "pa-0 pt-1 pb-1";
      }
    }
  }
};
</script>
