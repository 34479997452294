<template>
  <v-card
    color="#3db5a7"
    dark
    height="80"
    class="d-flex align-center justify-space-between mt-2 mb-2"
  >
    <div class="px-3">
      <p class="ma-0 caption">Total de ventas</p>
      <p class="text-h4 ma-0 mb-0 pl-1">
        $
        <v-progress-circular
          indeterminate
          size="28"
          v-if="loader"
        ></v-progress-circular>
        <span v-else>{{ totalSold }}</span>
        <span class="caption ml-2">MXN</span>
      </p>
    </div>
    <div class="px-3">
      <v-icon x-large>request_page</v-icon>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    totalSold: {
      type: Number,
      default: () => 0
    },
    loader: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style></style>
